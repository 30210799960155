@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1690770171085') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tubiao:before {
  content: "\e608";
}

.icon-tubiao1:before {
  content: "\e60e";
}

.icon-zuojiantou:before {
  content: "\e686";
}

