.every-loading{
    width: 128px;
    height: 128px;
    position: absolute;
    left:130px;
    top: 35vh;
    img{
        width: 100%;
        height: 100%;
    }
}